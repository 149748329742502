import React from 'react';
import { useQuery } from '@apollo/client';
import { Button, notification } from 'antd';
import gql from 'graphql-tag';

const CURRENT_VERSION = 136;
const pollInterval = 5 * 60 * 1000;

// Show a notification that auto refreshes the client if new version is found
function AutoRefreshNotification() {
  const { data } = useQuery<{ ping: number }>(
    gql`
      {
        ping
      }
    `,
    {
      pollInterval,
      fetchPolicy: 'no-cache',
    },
  );

  if (data && data.ping > CURRENT_VERSION) {
    const args = {
      key: 'autoupdate',
      message: '发现新版本',
      description: (
        <>
          请点击{' '}
          <Button type="primary" size="small">
            更新
          </Button>
        </>
      ),
      duration: 0,
      closeIcon: <></>,
      onClick: () => window.location.reload(),
      onClose: () => window.location.reload(),
    };
    setTimeout(() => notification.open(args), 0);
  }

  return <></>;
}

export default AutoRefreshNotification;
