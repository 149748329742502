import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { setContext } from '@apollo/client/link/context';
import { ConfigProvider, Spin } from 'antd';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';

// 1
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import ErrorBoundary from './components/ErrorBoundary';
import AutoRefreshNotification from './components/AutoRefreshNotification';
import message from './components/message';

// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './index.css';
import * as serviceWorker from './serviceWorker';
import ProgressModal from './components/ProgressModal';

import { badRequestReport } from './utils/errorReporting';

import { AUTH_TOKEN } from './utils/constants';

const browserHistory = createBrowserHistory();

const isAdminPortal = window.location.pathname.includes('/admin');
const UserApp = React.lazy(() => import('./pages/user/App'));
const AdminApp = React.lazy(() => import('./pages/admin/App'));

// 2
const httpLink = createUploadLink({
  uri: '/api',
});

const errorLink = onError(({ operation, response = {}, graphQLErrors, networkError, forward }) => {
  console.log('[response]', response);
  console.log('[network error]', networkError);
  console.log('[graphql error]', graphQLErrors);

  ProgressModal.hide();

  if (graphQLErrors) {
    const firstError = graphQLErrors[0];

    const code = firstError.extensions ? firstError.extensions.code : null;
    switch (code) {
      case 'FORBIDDEN':
      case 'UNAUTHENTICATED':
        localStorage.clear();
        message.warning('请先登录');
        browserHistory.replace(isAdminPortal ? '/admin/login' : '/login');
        // Now, pass the modified operation to the next link
        // in the chain. This effectively intercepts the old
        // failed request, and retries it with a new token
        // return forward(operation);
        break;
      case 'CODE_TAKEN':
        message.error('该会员号已被注册，如有疑问，请联系管理员');
        break;
      case 'EMAIL_TAKEN':
        message.error('该邮箱已被注册，如有疑问，请联系管理员');
        break;
      case 'NOT_REGISTERED':
        message.error(isAdminPortal ? '账号不存在，请联系管理员开通' : '账号不存在，请先注册');
        if (!isAdminPortal) {
          browserHistory.push('/signup');
        }
        break;
      case 'INVALID_OLD_PASSWORD':
        message.error('旧密码有误');
        break;
      case 'INVALID_PASSWORD':
        message.error('密码错误');
        break;
      case 'DUPLICATE_PACKAGE':
        if (isAdminPortal) {
          message.error('已存在运单号相同的包裹，请勿重复添加');
        } else {
          message.error(
            '已存在运单号相同的包裹(即使没有预报，仓库收到后也会入库并自动绑定到你)，请先检查我的包裹页面',
          );
        }
        break;
      case 'GROUP_NOT_OPEN':
        message.error('该团已截团，无法添加包裹，请加到其他拼邮团');
        break;
      case 'NO_OPERATOR':
        message.error('该操作员不存在');
        break;
      case 'NO_TRACKING_NUMBER':
        message.error('包裹缺少快递单号');
        break;
      case 'PACKAGE_IN_ANOTHER_ORDER':
        message.error('包裹已绑定了订单，请勿重复提交');
        break;
      case 'PACKAGE_LIST_IS_EMPTY':
        message.error('请先勾选包裹');
        break;
      case 'PACKAGE_NOT_BELONG_TO_USER':
        message.error('包裹不属于该客户');
        break;
      case 'PACKAGE_NOT_EXIST':
        message.error('包裹不存在');
        break;
      case 'CODE_NOT_EXIST':
        message.error('该会员号没有对应的客户，请检查是否输入有误');
        break;
      case 'NO_PACKAGE_RECEIVED_IN_SHIPPING_GROUP':
        message.error('截团失败，该拼邮团暂无已入库包裹');
        break;
      case 'SHIPPING_GROUP_NO_LEADER':
        message.error('该团未指定团长');
        break;
      case 'GROUP_NOT_EXIST':
        message.error('该拼邮团不存在');
        break;
      case 'PACKAGE_IN_SHIPPING_GROUP':
        message.error('选中的包裹已绑定了拼邮团');
        break;
      case 'PACKAGE_NOT_RECEIVED':
        message.error('选中的包裹还没入库');
        break;
      case 'TICKET_NOT_EXIST':
        message.error('该工单不存在');
        break;
      case 'USER_NOT_EXIST':
        message.error('该用户不存在');
        break;
      case 'GROUP_HAS_PACKAGE':
        message.error('拼邮团中还有包裹');
        break;
      case 'GROUP_PRIVATE_CODE_INCORRECT':
        message.error('参团口令有误');
        break;
      case 'ROUTE_NOT_ACTIVE':
        message.error('该页面已失效，请先返回列表页再重新修改');
        break;

      case 'UNKNOWN_QUERY':
      case 'SERVER_ERROR':
      case 'BAD_USER_INPUT':
      case 'GRAPHQL_VALIDATION_FAILED':
      case 'GRAPHQL_PARSE_FAILED':
      case 'INTERNAL_SERVER_ERROR':
      default:
        message.error('系统出错啦，请联系客服');
    }
  } else if (networkError) {
    const msg = `[Network error]: ${networkError}, URL: ${window.location.href}`;
    console.log(msg);
    if (
      networkError.toString()?.includes('网络连接已中断') ||
      networkError.toString()?.includes('The network connection was lost') ||
      networkError.toString()?.includes('似乎已断开与互联网的连接') ||
      networkError.toString()?.includes('The Internet connection appears to be offline') ||
      networkError.toString()?.includes('ソフトウェアにより接続が中止されました') ||
      networkError.toString()?.includes('未能找到使用指定主机名的服务器') ||
      networkError.toString()?.includes('软件导致连接中止') ||
      networkError
        .toString()
        ?.includes('A server with the specified hostname could not be found') ||
      networkError.toString()?.includes('Failed to fetch')
    )
      return;

    badRequestReport(msg);
    message.error('网络连接出错啦，请先尝试手动刷新页面，还是不行的话请联系客服');
  }
  // response.errors = undefined;
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// 3
const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ErrorBoundary>
    <Router history={browserHistory}>
      <ApolloProvider client={client}>
        <ConfigProvider locale={zhCN}>
          <Suspense
            fallback={
              <div style={{ textAlign: 'center', marginTop: '40vh' }}>
                <Spin />
              </div>
            }
          >
            {isAdminPortal ? <AdminApp /> : <UserApp />}
            <AutoRefreshNotification />
          </Suspense>
        </ConfigProvider>
      </ApolloProvider>
    </Router>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

moment.locale('zh-cn');
