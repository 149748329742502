import { TELEGRAM_BOT_CHAT_ID, TELEGRAM_BOT_URI } from "../config";

let userId;
let userName;
let userCode;

export function setErrorReportingUser(user?: {
  id?: string | null | undefined;
  name?: string | null | undefined;
  code?: string | null | undefined;
} | null) {
  userId = user?.id;
  userName = user?.name;
  userCode = user?.code;
}

export function badRequestReport(msg: string) {
  const env = window.location.host;
  const data = {
    chat_id: TELEGRAM_BOT_CHAT_ID,
    text: `[Client error]
UserAgent: ${navigator.userAgent}
URL: ${window.location.href}
User: ${userName} (${userCode}) ${userId}
${msg}
`,
  };
  console.log(data);
  if (/localhost/i.test(env)) return;
  fetch(TELEGRAM_BOT_URI, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}
