import { Modal, message } from 'antd';
// import MessageQueue from './MessageQueue';

// const errorMessageQueue = new MessageQueue();

let lastErrorMessage = '';

const customMessage = {
  success: (content, duration, onClose) => message.success(content, duration, onClose),
  error: (content, duration, onClose = () => {}) => {
    if (typeof duration === 'function') {
      onClose = duration;
      duration = undefined;
    }
    // new errors would be ignored until the first and only error is considered
    // outdated and cleared by queue
    // if (errorMessageQueue.list().length) return false;
    if (content === lastErrorMessage) return;
    lastErrorMessage = content;
    Modal.destroyAll();
    // const alertId = errorMessageQueue.add({ type: 'error', content }, duration);
    Modal.error({
      className: 'errorModalWrapper',
      icon: null,
      okText: '知道了',
      content,
      // onOk: () => { errorMessageQueue.remove(alertId); onClose() },
      onOk: () => {
        lastErrorMessage = '';
        onClose();
      },
      maskClosable: false,
    });
    // return alertId;
  },
  info: (content, duration, onClose) => message.info(content, duration, onClose),
  warning: (content, duration, onClose) => message.warning(content, duration, onClose),
  warn: (content, duration, onClose) => message.warn(content, duration, onClose),
  loading: (content, duration, onClose) => message.loading(content, duration, onClose),
};

export default customMessage;
