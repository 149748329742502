import React from 'react';
import { Card } from 'antd';

import PageTitle from '../../components/PageTitle';

import './index.css';

const content = `
1. 对于整箱包裹丢失的情况，通常是由相关物流承运公司理赔，具体政策请咨询客服。

2. 丢失险只针对整箱包裹部分丢失的情况，且不对任何商品损坏进行赔付。

3. 丢失险只负责赔偿客户的包裹在中国仓库到国外收货地址途中的丢失赔付。包裹若在进入仓库前丢失，本公司不负责赔付。

4. 对于丢失的包裹，按照购买原价进行赔偿，每个包裹赔付的最高金额为350人民币，赔付金额包含运费在内。

5. 客户必须要出示国内网站购买的订单详情和对应包裹运单号，丢失包裹内的商品必须同国内网站购买订单一致。

6. 丢失险不赔付违禁品、高仿品等违反相关国际快递和进出口规则的商品。

7. 丢失险不赔付海关扣押的商品。

8. 丢失险理赔期限是取货后两周内，超过两周不予理赔。

9. 若产生纠纷，一切解释权归本公司所有。

`;

function Insurance() {
  return (
    <div className="Insurance">
      <PageTitle>丢失保险</PageTitle>

      <Card bordered={false}>
        <div className="Insurance-content">{content}</div>
      </Card>
    </div>
  );
}

export default Insurance;
