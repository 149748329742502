import React from 'react';
import { Card, Alert } from 'antd';
import '../../pages/user/Tutorial/index.css';

function Contraband() {
  return (
    <div className="Tutorial">
      <Alert
        showIcon
        type="warning"
        message="有疑问的话可以微信咨询你的操作员。仓库打包时若发现有违禁品，会移出订单并备注"
        style={{ marginBottom: '1rem' }}
      />

      <Card bordered={false} title="海运快船普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            最新规定：带弱电弱磁的货物也可以寄了，纯电池不行。非国外品牌服装、鞋子、包包、饰品，中文书（非宗教信仰、政治类），不带电玩具（如电池可拆卸则可以拆出后运），小件床上用品（四件套等），部分家居类（如窗帘，小的收纳盒，浴巾、毛巾、地毯），烘焙模具，厨房工具（厨房神器、锅具等），餐具类刀（沙拉刀等），早教机、有声书（拆电池），玻璃制品（易碎类的需要包装好），少量陶瓷（一两件），爬行垫（注意尺寸限制），电子手表（小电池）等等
          </p>
          <p>
            尺寸要求：最长边 {'<='} 120cm，次长边 {'<='} 75cm，围长（两个短边之和 &times; 2 + 最长边）{'<='}{' '}
            265cm
          </p>
          <p>
            超大件货物加收费用标准：货物围长（两个短边之和 &times; 2 + 最长边）超265cm-320cm之间加260元/件，围长超320cm不接。最长边超120cm或次长边超75cm加收280元/件。无法合箱的超大件单件最低重量13kg起，不够13kg的按13kg计费。单件超22kg-38kg内加388元/件，单件超38kg的不接。以上费用叠加计算，例如：一个货物3kg，最长边超过1.2m，加收280元，围长超过2.65m又要加收260元，最低重量还要按13kg来计费
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            所有防疫用品（口罩、防护服等，不论是不是医用），液体（极少量也算），药品，粉末，香烟，烟丝，膏体，电池(干电池，锂电池/可充电电池等)，磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴），酒精，颜料，涂料，腐蚀性，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），原木制品，文物，钱币，刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），食品，化妆品，磁性玩具，名牌，A货，英文书籍，部分家居类（大件床上用品及所有家具），蜡烛制品，太阳能产品（不论是太阳能板还是带太阳能功能），一次性用品（纸袋，塑料袋，垃圾袋，购物袋），大量陶瓷制品（包括餐具），眼镜，汽车、摩托车用品（凡是与汽车、摩托车相关联的任何产品，车载手机支架可以），铅笔，中性笔，水彩笔，水彩/粉颜料，吸管，洗牙器，牙粉，牙线，螺丝，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池），小米扫地机器人，昂贵饰品。该渠道为包税线路，如有禁运品被查到需要征收高额关税以及罚款。
          </p>
        </div>
      </Card>
      <Card bordered={false} title="家具/超大件海运快船" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            家具或超大件的普货
          </p>
          <p>
            尺寸要求：无尺寸要求，免超大件货物附加费
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            所有防疫用品（口罩、防护服等，不论是不是医用），液体（极少量也算），药品，粉末，香烟，烟丝，膏体，电池(干电池，锂电池/可充电电池等)，磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴），酒精，颜料，涂料，腐蚀性，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），原木制品，文物，钱币，刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），食品，化妆品，磁性玩具，名牌，A货，英文书籍，蜡烛制品，太阳能产品（不论是太阳能板还是带太阳能功能），一次性用品（纸袋，塑料袋，垃圾袋，购物袋），大量陶瓷制品（包括餐具），眼镜，汽车、摩托车用品（凡是与汽车、摩托车相关联的任何产品，车载手机支架可以），铅笔，中性笔，水彩笔，水彩/粉颜料，吸管，洗牙器，牙粉，牙线，螺丝，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池），小米扫地机器人，昂贵饰品。该渠道为包税线路，如有禁运品被查到需要征收高额关税以及罚款。
          </p>
        </div>
      </Card>
      <Card bordered={false} title="海运快船敏感货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/6000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>

          <h4 style={{ color: '#2c662d' }}>可运</h4>
          <p>
            该渠道要求最为宽松，大部分海运普货禁运的东西（除去以下禁运的）都可以考虑走该渠道。非液体、非白色粉状、非膏状物、保质期长的食品（3个月以上），方便面，带包装的粉末类调味料，辣椒面、抹茶粉等，足贴，片状面膜，眼影，口红，唇膏，散粉，隐形眼镜、指甲油等化妆品，等等。请注意：一旦被海关查出违禁品，整团都会被没收，这种情况下每人赔偿两倍运费。
          </p>
          <p>
            尺寸要求：最长边 {'<='} 120cm，次长边 {'<='} 75cm，围长（两个短边之和 &times; 2 + 最长边）{'<='}{' '}
            265cm
          </p>
          <p>
            超大件货物加收费用标准：货物围长（两个短边之和 &times; 2 + 最长边）超265cm-320cm之间加260元/件，围长超320cm不接。最长边超120cm或次长边超75cm加收280元/件。无法合箱的超大件单件最低重量13kg起，不够13kg的按13kg计费。单件超22kg-38kg内加388元/件，单件超38kg的不接。以上费用叠加计算，例如：一个货物3kg，最长边超过1.2m，加收280元，围长超过2.65m又要加收260元，最低重量还要按13kg来计费
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            白色粉末，枪支或配件（包括仿真枪），易挥发性化工产品，药品，纯电池（干电池，锂电池，可充电电池等），酒精，颜料，涂料，油漆，腐蚀性，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），打火机，文物，钱币，刀具（管制刀具，包括菜刀）
          </p>
        </div>
      </Card>

      <Card bordered={false} title="DHL / Fedex 空运普货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>
          <h4 style={{ color: '#2c662d' }}>可运</h4>

          <p>
            日常用品：服装、图书、玩具、家居、饰品、家用电器（不带电池、马达、喇叭，非磁性）等
            <br />
            品牌产品：仅限国内品牌的服装、鞋子、包包，国外品牌和带国外卡通形象的商品一律不行
            <br />
            部分化妆品：化妆笔、毛刷，另有专门的非液体类化妆品专线请咨询客服
            <br />
            非医用防疫物资：有专线可以走请咨询客服
          </p>
          <p>
            Fedex/DHL 尺寸要求：最长边 {'<='} 1.2m，三边之和 {'<='} 2m，单件限重
            30kg，如果超出尺寸要额外增加费用，另询客服
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
            空运要求更为严格，所有海运普货禁运的，空运普货都禁运。除此之外还包括：所有国外品牌和带国外卡通形象的商品，带医用字样的防疫用品（口罩、防护服等），腐蚀性，酒精，压力罐，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），不适合邮递条件的物品，包括液体（如酒、化妆水等）、乳霜类化妆品（乳霜、眼霜、睫毛膏、牙膏等）、颗粒、粉末、食品、茶叶、中药、西药、香烟、带电池的（干电池，锂电池/可充电电池等）、充电宝、磁铁（包括带有电机、喇叭和其他有磁性的物品，如冰箱贴）、有攻击性的用具（如菜刀、剪刀）等，以及A货，金项链，玉器等贵重饰品，蓝牙产品（蓝牙玩具，蓝牙耳机、蓝牙键盘，除非是可以拆除的干电池）
          </p>
        </div>
      </Card>

      <Card bordered={false} title="UPS空运敏感货" style={{ marginBottom: '1rem' }}>
        <div className="Tutorial-content">
          <h4>体积重计算</h4>
          <p>
            体积重(kg)=长(cm)&times;宽(cm)&times;高(cm)/5000，体积重和实际重量哪个大按哪个计费。尺寸计算的时候根据国际标准是向上取整，比如
            20.1cm 会按 21cm 计算
          </p>
          <h4 style={{ color: '#2c662d' }}>可运</h4>

          <p>
            食品，仿货，茶叶，化妆品（液体的话小于 550ml），粉末类（除白色粉末），非液体药品等敏感货
          </p>
          <p>
            尺寸要求：最长边 {'<='} 120cm，次长边 {'<='} 75cm，围长（两个短边之和 &times; 2 + 最长边）{'<='}{' '}
            265cm
          </p>
          <p>
            超大件货物加收费用标准：货物围长（两个短边之和 &times; 2 + 最长边）超265cm-320cm之间加260元/件，围长超320cm不接。最长边超120cm或次长边超75cm加收280元/件。无法合箱的超大件单件最低重量13kg起，不够13kg的按13kg计费。单件超22kg-38kg内加388元/件，单件超38kg的不接。以上费用叠加计算，例如：一个货物3kg，最长边超过1.2m，加收280元，围长超过2.65m又要加收260元，最低重量还要按13kg来计费
          </p>

          <h4 style={{ color: '#9f3a38' }}>禁运</h4>

          <p>
          白色粉末，香烟，腐蚀性，油漆，酒精，压力罐，打火机，易燃易爆（包括自热火锅加热包、发热眼贴、暖宝宝贴等），刀具（管制刀具，包括菜刀），枪支或配件（包括仿真枪），纯电池，充电宝，液体药品，有攻击性的用具（如菜刀、剪刀）等
          </p>
        </div>
      </Card>
    </div>
  );
}

export default Contraband;
