/**
 *
 * ProgressModal
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd';

const ProgressModal = () => {
  const modal = (
    <div className="modalMask">
      <div className="modalContainer">
        <Spin />
      </div>
    </div>
  );

  return modal;
};

let bodyModalDiv;
function showModal() {
  if (!bodyModalDiv) {
    bodyModalDiv = document.createElement('div');
    document.body.appendChild(bodyModalDiv);
  }

  ReactDOM.render(<ProgressModal />, bodyModalDiv);
  document.body.style.overflow = 'hidden';
}

function hideModal() {
  if (!bodyModalDiv) {
    bodyModalDiv = document.createElement('div');
    document.body.appendChild(bodyModalDiv);
  }

  ReactDOM.render(<div />, bodyModalDiv);
  document.body.style.overflow = '';
}

const ProgressModalExport = {
  show: showModal,
  hide: hideModal,
};

export default ProgressModalExport;
