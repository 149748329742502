import React, { ReactNode } from 'react';
import Error from '../Error';
import { badRequestReport } from '../../utils/errorReporting';

class ErrorBoundary extends React.Component<{ children: ReactNode }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info = { componentStack: '' }) {
    // for production env: handling console errors and send message through telegram bot
    const stackInfo = info.componentStack; //.split('\n')[1].trim();
    const message = `-- CAUGHT BY ErrorBoundary --\n[ERROR] ${error}\n[STACK INFO] ${stackInfo}\n---------------------------------`;
    badRequestReport(message);
    if (message.includes('ChunkLoadError')) {
      badRequestReport('WINDOW_RELOADED');
      window.location.reload();
    }
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return (
        <div className="errorBoundaryWrapper">
          <Error />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
