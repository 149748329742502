import React from 'react';
import DuckSvg from './DuckSvg';

function Error(props: { message?: string }) {
  return (
    <div className="Error" style={{ textAlign: 'center', margin: '1rem' }}>
      <div style={{ width: '100px', margin: 'auto' }}>{DuckSvg}</div>
      <div style={{ fontSize: '1.1rem', color: '#806538', marginTop: '1rem' }}>
        {props.message ? (
          <p>{props.message}</p>
        ) : (
          <>
            <p>啊哦，系统出错了</p>
            <p>请先尝试手动刷新页面</p>
            <p>还是不行的话请联系客服</p>
          </>
        )}
      </div>
    </div>
  );
}

export default Error;
